import React from "react";

function FlightFear() {
  return (
    <div className=" p-3">
      <table className=" w-full">
        <thead>
          <tr>
            <th className=" text-left text-sm text-gray-400 font-normal">
              Fare Summary
            </th>
            <th className=" text-center text-sm text-gray-400 font-normal">
              Base Fare
            </th>
            <th className=" text-right text-sm text-gray-400 font-normal">
              Tax
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className=" text-sm text-primary py-1">Adult x 1</td>
            <td className=" text-center text-sm text-primary py-1">AED 369</td>
            <td className=" text-right text-sm text-primary py-1">AED 369</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default FlightFear;
