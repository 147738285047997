import React from "react";
import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";

export default function TourDetailsPageSearch() {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const toggleDropDown = () => {
    setDropDownOpen(!dropDownOpen);
  };
  document.addEventListener("mouseup", (e) => {
    const option = document.getElementById("option");
    if (!option.contains(e.target) || e.target !== option) {
      setDropDownOpen(false);
    }
  });

  return (
    <div className="">
      <div className=" w-full border rounded px-3 py-2">
        <p className=" text-sm text-gray-500">January Date</p>
        <p className=" text-primary">
          <span className="font-bold text-lg">15</span> Jan'23
        </p>
      </div>
      <div className=" w-full border rounded px-3 py-2 mt-5">
        <p className=" text-sm text-gray-500">Travelers</p>
        <p className=" text-primary">
          <span className="font-bold text-lg">1</span> Adults
        </p>
      </div>
      <div className=" w-full mt-5 relative" id="option">
        <p className=" text-sm text-primary font-bold">Options</p>
        <div
          className=" border rounded py-2 px-3  mt-1 flex justify-between items-center cursor-pointer"
          onClick={toggleDropDown}
        >
          <p>Select Option</p>
          <FiChevronDown className=" text-gray-500" />
        </div>
        {dropDownOpen && (
          <div className=" absolute top-20 left-0 w-full bg-white p-3 rounded shadow">
            <div className=" hover:bg-gray-100 cursor-pointer rounded px-1 py-[2px]">
              <p className=" text-primary font-bold ">Uper Deck</p>
              <p className=" text-gray-500 text-sm">Up to 1 Travelers</p>
            </div>
            <div className=" hover:bg-gray-100 cursor-pointer rounded px-1 py-[2px] mt-3 ">
              <p className=" text-primary font-bold ">Lower Deck</p>
              <p className=" text-gray-500 text-sm">Up to 1 Travelers</p>
            </div>
          </div>
        )}
        <div className=" mt-3">
          <p className=" text-sm text-gray-500 text-center">Starting from</p>
          <p className=" text-sm text-gray-500 text-center line-through">
            AED 2,869
          </p>
          <p className=" text-primary font-bold text-center">AED 2,776</p>
          <button className=" bg-yellow-400 text-primary w-full py-2 mt-3 font-bold text-sm rounded shadow-lg hover:shadow-yellow-300">
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}
