import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SliderItem from "./SliderItem";
import photo1 from "../../Photos/hotel/details/hotel (1).jpg";
import photo2 from "../../Photos/hotel/details/hotel (2).jpg";
import photo3 from "../../Photos/hotel/details/hotel (3).jpg";
import photo4 from "../../Photos/hotel/details/hotel (4).jpg";
import {
  AiFillCar,
  AiFillInfoCircle,
  AiFillStar,
  AiOutlineWifi,
} from "react-icons/ai";
import { ImLocation } from "react-icons/im";
import Mapcomponent from "../../Components/Hotdeals/Mapcomponent";
import {
  MdChildCare,
  MdFreeBreakfast,
  MdOutlineAccessibleForward,
  MdPets,
} from "react-icons/md";
import {
  BsFillTelephoneFill,
  BsFillDoorOpenFill,
  BsCheck2,
  BsCalendar2Date,
} from "react-icons/bs";
import { CgGym } from "react-icons/cg";
import { IoCalendarOutline, IoPeople } from "react-icons/io5";
import SearchComponent from "../../Components/Search/SearchComponent";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import bank1 from "../../Photos/bank/bank (1).svg";
import bank2 from "../../Photos/bank/bank (2).svg";
import bank3 from "../../Photos/bank/bank (3).svg";

function HotelBookingDetails() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 2000, min: 1400 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1400, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className=" pt-20 w-full">
      <div className="w-full xl:w-[1160px] mx-auto px-5 py-10">
        <div className=" w-full grid grid-cols-2 gap-5 bg-white pb-5">
          <div className=" col-span-2 lg:col-span-1 grid grid-cols-6">
            <div className="gd-carousel-wrapper col-span-6 sm:col-span-5">
              <Carousel
                arrows={true}
                responsive={responsive}
                className="gd-carousel"
                autoPlay={true}
                autoPlaySpeed={3000}
                infinite={true}
              >
                <div className=" mr-5 h-full">
                  <SliderItem />
                </div>
                <div className=" mr-5 h-full">
                  <SliderItem />
                </div>
                <div className=" mr-5 h-full">
                  <SliderItem />
                </div>
                <div className=" mr-5 h-full">
                  <SliderItem />
                </div>
                <div className=" mr-5 h-full">
                  <SliderItem />
                </div>
                <div className=" mr-5 h-full">
                  <SliderItem />
                </div>
                <div className=" mr-5 h-full">
                  <SliderItem />
                </div>
              </Carousel>
            </div>
            <div className=" col-span-1 w-full h-full flex sm:flex-col gap-5">
              <img src={photo1} alt="" className="" />
              <img src={photo2} alt="" className="" />
              <img src={photo3} alt="" className="" />
              <img src={photo4} alt="" className="" />
            </div>
          </div>
          <div className=" col-span-2 lg:col-span-1">
            <div className=" ">
              <div className=" flex gap-3 mt-2">
                <div className=" border border-blue-400 rounded flex items-center justify-center px-2">
                  <p className=" text-blue-400 text-sm">HOTEL</p>
                </div>
                <div className=" flex items-center">
                  <AiFillStar className=" text-blue-600" />
                  <AiFillStar className=" text-blue-600" />
                  <AiFillStar className=" text-blue-600" />
                </div>
              </div>
              <div className=" mt-1">
                <p className=" font-bold text-xl text-primary">
                  Windy Terrace Boutique Hotel
                </p>
                <div className=" flex items-center gap-1">
                  <ImLocation className=" text-blue-600 text-[12px]" />
                  <p className=" text-[12px] text-blue-600">
                    Plot-40, Block-C, Kolatoli, Cox's Bazar, Bangladesh
                  </p>
                </div>
              </div>
              <div className=" mt-2">
                <Mapcomponent />
              </div>
              <div className=" mt-2">
                <p className=" text-[12px] text-gray-500">What's Nearby</p>
                <div className=" flex items-center gap-1">
                  <ImLocation className=" text-gray-500 text-sm" />
                  <p className=" text-[12px] text-gray-500">
                    0.8 km from Kolatoli Beach
                  </p>
                </div>
              </div>
              <div className=" mt-3">
                <div className=" flex gap-2 mt-2">
                  <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                    <p className="  text-[12px]">Smoke detector</p>
                  </div>
                  <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                    <AiFillCar className=" text-sm" />
                    <p className="  text-[12px]"> Airport Shuttle Service</p>
                  </div>
                </div>
                <div className=" flex gap-2 mt-2">
                  <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                    <p className="  text-[12px]">Car Rental</p>
                  </div>
                  <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                    <BsFillTelephoneFill className=" text-sm" />
                    <p className="  text-[12px]"> Secretarial Service</p>
                  </div>
                  <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                    <CgGym className=" text-sm" />
                    <p className="  text-[12px]">Gym</p>
                  </div>
                </div>
                <div className=" flex gap-2 mt-2">
                  <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                    <MdOutlineAccessibleForward className="" />
                    <p className="  text-[12px]">Concierge</p>
                  </div>
                  <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                    <BsFillDoorOpenFill className=" text-sm" />
                    <p className="  text-[12px]">Bellybody Service</p>
                  </div>
                  <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                    <AiOutlineWifi className=" text-sm" />
                    <p className="  text-[12px]">Free Wi-Fi</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* search component */}
        {/* <div className=' w-full rounded-md bg-white p-10 mt-5 '> */}
        {/* <p>Search Component</p> */}
        {/* <SearchComponent /> */}
        {/* </div> */}
        <div className=" mt-5">
          <div className=" bg-white p-5 sticky top-12 lg:top-20 left-0 z-50">
            <div className=" flex items-center flex-wrap gap-2 md:gap-5 border-b">
              <Link
                activeClass="border-b-2 border-blue-500 text-blue-400"
                to="rooms"
                className={` pb-3 text-gray-500 `}
                spy={true}
                smooth={true}
                offset={-160}
                duration={500}
              >
                <span className=" text-[10px] md:text-[12px] cursor-pointer">
                  ROOMS
                </span>
              </Link>
              <Link
                activeClass="border-b-2 border-blue-500 text-blue-400"
                to="hotel-description"
                className="pb-3 text-gray-500"
                spy={true}
                smooth={true}
                offset={-150}
                duration={500}
              >
                <span className=" text-[10px] md:text-[12px] cursor-pointer">
                  HOTEL DESCRIPTION
                </span>
              </Link>
              <Link
                activeClass="border-b-2 border-blue-500 text-blue-400"
                to="facilities"
                spy={true}
                className="pb-3 text-gray-500"
                smooth={true}
                offset={-150}
                duration={500}
              >
                <span className=" text-[10px] md:text-[12px] cursor-pointer ">
                  FACILITIES
                </span>
              </Link>
              <Link
                activeClass="border-b-2 border-blue-500 text-blue-400"
                to="policy"
                spy={true}
                className="pb-3 text-gray-500"
                smooth={true}
                offset={-150}
                duration={500}
              >
                <span className=" text-[10px] md:text-[12px] cursor-pointer">
                  POLICY
                </span>
              </Link>
            </div>
          </div>
          {/* Rooms Section start */}
          <div id="rooms">
            <div className=" mt-5 grid grid-cols-5 gap-3 border-b pb-5">
              <div className=" col-span-5 md:col-span-2">
                <p className=" text-xl font-bold text-primary">Twin Deluxe</p>
                <div className=" mt-5 flex items-center gap-3">
                  <img src={photo1} alt="" className=" rounded-md w-28" />
                  <img src={photo2} alt="" className=" rounded-md w-28" />
                </div>
                <div className=" mt-5">
                  <div className=" flex items-center gap-1 mb-1">
                    <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                    <span className=" text-gray-500">Wi-Fi</span>
                  </div>
                  <div className=" flex items-center gap-1 mb-1">
                    <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                    <span className=" text-gray-500">TV</span>
                  </div>
                  <div className=" flex items-center gap-1 mb-1">
                    <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                    <span className=" text-gray-500">Tea and Coffee</span>
                  </div>
                  <div className=" flex items-center gap-1 mb-1">
                    <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                    <span className=" text-gray-500">Air Conditioning</span>
                  </div>
                  <div className=" flex items-center gap-1 mb-1">
                    <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                    <span className=" text-gray-500">Toiletries</span>
                  </div>
                  <div className=" flex items-center gap-1 mb-1">
                    <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                    <span className=" text-gray-500">Hot Water</span>
                  </div>
                </div>
              </div>
              <div className=" col-span-5 md:col-span-3">
                <div className=" w-full bg-gray-50 rounded p-5 flex justify-between gap-5 flex-wrap">
                  <div>
                    <p className=" text-primary font-semibold text-lg">
                      Option 1
                    </p>
                    <div className=" flex items-center gap-1 mt-1">
                      <p className=" text-gray-500 text-[12px]">Refundable</p>
                      <AiFillInfoCircle className=" text-sm text-gray-500" />
                    </div>

                    <div className=" flex items-center gap-1 mt-[6px]">
                      <IoPeople className=" text-gray-500" />
                      <p className=" text-[12px] text-gray-500">
                        2 Adults, 1 Child
                      </p>
                    </div>
                    <div className=" flex items-center gap-1 mt-[6px]">
                      <MdFreeBreakfast className=" text-gray-500" />
                      <p className=" text-[12px] text-gray-500">
                        Breakfast Included
                      </p>
                    </div>
                    <p className=" text-[12px] text-gray-500 mt-[6px]">
                      Bed and Breakfast - Refundable
                    </p>
                    <ul className="  text-[12px] text-gray-500 mt-[6px] list-disc pl-5">
                      <li>
                        Free cancellation before 00:01 on Tue, 17 Jan 2023
                      </li>
                    </ul>
                  </div>
                  <div className=" md:text-right">
                    <div className=" flex gap-3 items-center">
                      <span className=" text-gray-500 line-through">
                        BDT 7,001
                      </span>
                      <p className=" font-bold text-primary text-lg">
                        BDT 3,046
                      </p>
                    </div>
                    <p className=" text-gray-500 text-sm mt-1">
                      + BDT 806 Tax & Fees
                    </p>
                    <p className=" text-gray-500 text-sm mt-1">+for 1 Night</p>
                    <div className=" flex md:justify-end mt-1">
                      <div className=" px-3 py-1 bg-green-100 rounded-3xl flex items-center justify-center">
                        <span className=" text-blue-500 text-[12px]">
                          56% off
                        </span>
                      </div>
                    </div>
                    <div className=" mt-10 flex  md:justify-end">
                      <button className=" bg-primary py-[2px] md:py-1 px-1 md:px-2 text-sm md:text-base text-white font-bold rounded hover:shadow-lg">
                        Choose Room 1
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" mt-5 grid grid-cols-5 gap-3">
              <div className=" col-span-5 md:col-span-2">
                <p className=" text-xl font-bold text-primary">King Deluxe</p>
                <div className=" mt-5 flex items-center gap-3">
                  <img src={photo1} alt="" className=" rounded-md w-28" />
                  <img src={photo2} alt="" className=" rounded-md w-28" />
                </div>
                <div className=" mt-5">
                  <div className=" flex items-center gap-1 mb-1">
                    <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                    <span className=" text-gray-500">Wi-Fi</span>
                  </div>
                  <div className=" flex items-center gap-1 mb-1">
                    <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                    <span className=" text-gray-500">TV</span>
                  </div>
                  <div className=" flex items-center gap-1 mb-1">
                    <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                    <span className=" text-gray-500">Tea and Coffee</span>
                  </div>
                  <div className=" flex items-center gap-1 mb-1">
                    <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                    <span className=" text-gray-500">Air Conditioning</span>
                  </div>
                  <div className=" flex items-center gap-1 mb-1">
                    <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                    <span className=" text-gray-500">Toiletries</span>
                  </div>
                  <div className=" flex items-center gap-1 mb-1">
                    <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                    <span className=" text-gray-500">Hot Water</span>
                  </div>
                </div>
              </div>
              <div className=" col-span-5 md:col-span-3">
                <div className=" w-full bg-gray-50 rounded p-5 flex justify-between gap-5 flex-wrap">
                  <div>
                    <p className=" text-primary font-semibold text-lg">
                      Option 1
                    </p>
                    <div className=" flex items-center gap-1 mt-1">
                      <p className=" text-gray-500 text-[12px]">Refundable</p>
                      <AiFillInfoCircle className=" text-sm text-gray-500" />
                    </div>

                    <div className=" flex items-center gap-1 mt-[6px]">
                      <IoPeople className=" text-gray-500" />
                      <p className=" text-[12px] text-gray-500">
                        2 Adults, 1 Child
                      </p>
                    </div>
                    <div className=" flex items-center gap-1 mt-[6px]">
                      <MdFreeBreakfast className=" text-gray-500" />
                      <p className=" text-[12px] text-gray-500">
                        Breakfast Included
                      </p>
                    </div>
                    <p className=" text-[12px] text-gray-500 mt-[6px]">
                      Bed and Breakfast - Refundable
                    </p>
                    <ul className="  text-[12px] text-gray-500 mt-[6px] list-disc pl-5">
                      <li>
                        Free cancellation before 00:01 on Tue, 17 Jan 2023
                      </li>
                    </ul>
                  </div>
                  <div className=" md:text-right">
                    <div className=" flex gap-3 items-center">
                      <span className=" text-gray-500 line-through">
                        BDT 7,001
                      </span>
                      <p className=" font-bold text-primary text-lg">
                        BDT 3,046
                      </p>
                    </div>
                    <p className=" text-gray-500 text-sm mt-1">
                      + BDT 806 Tax & Fees
                    </p>
                    <p className=" text-gray-500 text-sm mt-1">+for 1 Night</p>
                    <div className=" flex md:justify-end mt-1">
                      <div className=" px-3 py-1 bg-green-100 rounded-3xl flex items-center justify-center">
                        <span className=" text-blue-500 text-[12px]">
                          56% off
                        </span>
                      </div>
                    </div>
                    <div className=" mt-10 flex md:justify-end">
                      <button className=" bg-primary py-[2px] md:py-1 px-1 md:px-2 text-white font-bold rounded hover:shadow-lg">
                        Choose Room 1
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Rooms Section end */}
          {/* description section start */}
          <div id="hotel-description">
            <div className=" w-full p-5 bg-blue-50 mt-5">
              <p className=" text-primary font-semibold text-lg">
                Hotel Description
              </p>
              <div className=" flex gap-3 md:gap-5 items-center flex-wrap">
                <p className=" text-primary font-semibold">
                  Number of Rooms: 60
                </p>
                <p className=" text-primary font-semibold">
                  Number of Floors: 10
                </p>
              </div>
              <p className=" mt-5 text-gray-500 text-[12px]">
                Windy Terrace Boutique Hotel features air-conditioned
                accommodation in Cox's Bazar. Among the facilities of this
                property are a restaurant, a 24-hour front desk and room
                service, along with free WiFi. Private parking can be arranged
                at an extra charge.The rooms at the hotel are fitted with a
                seating area. At Windy Terrace Boutique Hotel rooms have a desk,
                a flat-screen TV and a private bathroom.The accommodation offers
                a continental or Asian breakfast.
              </p>
            </div>
            <div className=" bg-white w-full p-5 grid grid-cols-5 gap-3">
              <div className=" col-span-5 md:col-span-3">
                <Mapcomponent />
              </div>
              <div className=" col-span-5 md:col-span-2">
                <p className=" text-gray-500 text-sm">Interest Points</p>
                <div className=" flex items-center gap-1 mt-1">
                  <ImLocation className=" text-gray-500 text-sm" />
                  <p className=" text-sm text-gray-500">
                    0.8 km from Kolatoli Beach
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* description section end */}
          {/* Facilities section start */}
          <div id="facilities" className=" bg-white p-5 w-full mt-5">
            <p className=" font-bold text-lg text-gray-700">Facilities</p>
            <div>
              <p className=" text-gray-600 mt-2">Business Facilities</p>
              <div className=" grid grid-cols-1 sm:grid-cols-4 py-4">
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">
                    Secretarial Service
                  </span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">Auditorium</span>
                </div>
              </div>
            </div>
            <div className=" mt-3">
              <p className=" text-gray-600 mt-2">
                Fitness & Wellness Facilities
              </p>
              <div className=" grid grid-cols-4 py-4">
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">Gym</span>
                </div>
              </div>
            </div>
            <div className=" mt-3">
              <p className=" text-gray-600 mt-2">Food & Drink</p>
              <div className=" grid grid-cols-4 py-4">
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">Set Menu Lunch</span>
                </div>
              </div>
            </div>
            <div className=" mt-3">
              <p className=" text-gray-600 mt-2">General</p>
              <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2 py-4">
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">Smoke detector</span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">Elevator</span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">Clothes Dryer</span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">No Alcohol</span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">Towel</span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">
                    Air Conditioning
                  </span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">
                    Currency Exchange
                  </span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">Lockers</span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">
                    Coffee/Tea in Lobby
                  </span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">Check-In</span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">Garden</span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">
                    Couple Friendly
                  </span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">Sofa Bed</span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">Check-Out</span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">ID Required</span>
                </div>
              </div>
            </div>
            <div className=" mt-3">
              <p className=" text-gray-600 mt-2">Media & Technology</p>
              <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 py-4">
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">Computer</span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">Printer</span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">Printer</span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">
                    Mobile Phone Coverage
                  </span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">Photocopier</span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">Telephone</span>
                </div>
              </div>
            </div>
            <div className=" mt-3">
              <p className=" text-gray-600 mt-2">Parking</p>
              <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 py-4">
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">
                    Free Large Vehicle Parking
                  </span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">
                    Large Vehicle Parking
                  </span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">Garage</span>
                </div>
              </div>
            </div>
            <div className=" mt-3">
              <p className=" text-gray-600 mt-2">Safety & Security</p>
              <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 py-4">
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">
                    24-Hour Security
                  </span>
                </div>
              </div>
            </div>
            <div className=" mt-3">
              <p className=" text-gray-600 mt-2">Services & Extras</p>
              <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 py-4">
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">
                    Tours/Ticket Assistance
                  </span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">
                    Medical Service
                  </span>
                </div>
              </div>
            </div>
            <div className=" mt-3">
              <p className=" text-gray-600 mt-2">Transportation</p>
              <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 py-4">
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">
                    Airport Shuttle Service
                  </span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">Car Rental</span>
                </div>
                <div className=" flex items-center gap-1 mb-1 ">
                  <BsCheck2 className=" text-blue-500 text-lg font-semibold" />
                  <span className=" text-gray-500 text-sm">
                    Free Airport Shuttle Service
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* Facilities section end */}
          {/* Policy section start */}
          <div id="policy" className=" w-full bg-white p-5 mt-5">
            <p className=" font-bold text-lg text-gray-700">Policy</p>
            <div className=" grid grid-cols-4 md:grid-cols-5 gap-3">
              <div className=" col-span-4 md:col-span-1">
                <div className=" flex items-center gap-2 mt-2">
                  <IoCalendarOutline />
                  <span className=" text-gray-500 text-sm">Check In</span>
                </div>
              </div>
              <div className=" col-span-4 md:col-span-3">
                <div className=" w-full rounded-md bg-gray-100 h-3 relative mt-2">
                  <div className=" w-[55%] h-full bg-blue-500 absolute top-0 right-0 rounded-r-md ">
                    <span className=" absolute -top-5 left-0 text-[12px] text-gray-500">
                      12:00:00
                    </span>
                  </div>
                </div>
              </div>
              <div className=" hidden md:block col-span-1"></div>
            </div>
            <div className=" grid grid-cols-4 md:grid-cols-5">
              <div className=" col-span-4 md:col-span-1">
                <div className=" flex items-center gap-2 mt-5">
                  <IoCalendarOutline />
                  <span className=" text-gray-500 text-sm">Check In</span>
                </div>
              </div>
              <div className=" col-span-4 md:col-span-3">
                <div className=" w-full rounded-md bg-gray-100 h-3 relative mt-8">
                  <div className=" w-[42%] h-full bg-blue-500 absolute top-0 left-0 rounded-l-md ">
                    <span className=" absolute -top-5 right-0 text-[12px] text-gray-500">
                      11:00:00
                    </span>
                  </div>
                </div>
              </div>
              <div className=" hidden md:block col-span-1"></div>
            </div>
            <div className=" grid grid-cols-4 md:grid-cols-5">
              <div className=" col-span-4 md:col-span-1">
                <div className=" flex items-center gap-2 mt-5">
                  <MdChildCare />
                  <span className=" text-gray-500 text-sm">Child Policy</span>
                </div>
              </div>
              <div className=" col-span-4 md:col-span-3">
                <div className=" w-full mt-6">
                  <span className=" text-[12px] text-gray-500">Allowed</span>
                  <p className=" text-[12px] text-gray-500 mt-5">
                    .• Up to 1 Child below 5 Years can stay in the same room &
                    enjoy a Complimentary Breakfast.• Children from 5 years or
                    above will be charged BDT 1000/= for extra bed & breakfast
                  </p>
                </div>
              </div>
              <div className=" hidden md:block col-span-1"></div>
            </div>
            <div className=" grid grid-cols-4 md:grid-cols-5">
              <div className=" col-span-4 md:col-span-1">
                <div className=" flex items-center gap-2 mt-5">
                  <MdPets />
                  <span className=" text-gray-500 text-sm">Pet Policy</span>
                </div>
              </div>
              <div className=" col-span-4 md:col-span-3">
                <div className=" w-full mt-6">
                  <span className=" text-[12px] text-gray-500">
                    Not Allowed
                  </span>
                </div>
              </div>
              <div className=" hidden md:block col-span-1"></div>
            </div>
            <div className=" grid grid-cols-4 md:grid-cols-5">
              <div className=" col-span-4 md:col-span-1">
                <div className=" flex items-center gap-2 mt-5">
                  <MdPets />
                  <span className=" text-gray-500 text-sm">House Rules</span>
                </div>
              </div>
              <div className=" col-span-4 md:col-span-3">
                <div className=" w-full mt-6">
                  <span className=" text-[12px] text-gray-500">
                    During Blackout/Long Holidays period Cancellation policy
                    will not applicable Each guest has to present a copy of
                    their valid NID/other forms of identification documents
                    during check-in. Guests should carry their vaccination
                    certificate during check-in.
                  </span>
                </div>
              </div>
              <div className=" hidden md:block col-span-1"></div>
            </div>
            <div className=" grid grid-cols-4 md:grid-cols-5 items-center">
              <div className=" col-span-1">
                <div className=" flex items-center gap-2 mt-5">
                  <MdPets />
                  <span className=" text-gray-500 text-sm">
                    Property accepts
                  </span>
                </div>
              </div>
              <div className=" col-span-4 md:col-span-3">
                <div className=" w-full mt-6 flex items-center gap-3">
                  <img src={bank1} alt="" className=" w-14" />
                  <img src={bank2} alt="" />
                  <img src={bank3} alt="" />
                </div>
              </div>
              <div className=" hidden md:block col-span-1"></div>
            </div>
          </div>
          {/* Policy section end */}
        </div>
      </div>
    </div>
  );
}

export default HotelBookingDetails;
