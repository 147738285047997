import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import bg from "../../Photos/BG.png";
import FlightFear from "./FlightFear";
import FlightRules from "./FlightRules";
import { HiArrowNarrowRight } from "react-icons/hi";
import FlightSegment from "./FlightSegment";
import Baggage from "./Baggage";

function FlightBookComponent() {
  const [showDetails, setShowDetails] = useState(false);
  const [activeButton, setAcctiveButton] = useState("baggage");
  const location = useLocation();
  const [showDetailsRoundTrip, setShowDetailsRoundTrip] = useState(false);
  const roundTrip = location.search.split("?")[1].split("&")[0].split("=")[1];

  const toggleShowDetailsRoundTrip = () => {
    setShowDetailsRoundTrip(!showDetailsRoundTrip);
  };

  const navigation = useNavigate();

  const toggleShowDetails = () => {
    setShowDetails(!showDetails);
  };
  const toggleActiveButton = (text) => {
    setAcctiveButton(text);
  };
  const gotoDetailsPage = () => {
    navigation("/flight/booking-details");
  };

  return (
    <div className=" w-full bg-white shadow-custom rounded-md mt-5 z-1 ">
      <div className=" w-full grid grid-cols-7">
        <div className=" col-span-7 md:col-span-5  p-3 h-full">
          <div className=" w-full grid grid-cols-3 md:grid-cols-5 gap-3">
            <div className="flex items-center gap-1 md:col-span-1 col-span-3">
              <img src={bg} alt="" className=" w-12 h-12" />
              <p className=" text-gray-400 text-[12px]">Emirates Airlines</p>
            </div>
            <div className=" ">
              <p className="font-medium text-lg text-primary">15:45</p>
              <p className=" text-sm mt-[6px]">DXB</p>
            </div>
            <div className=" flex flex-col justify-start items-start sm:items-center">
              <p className=" text-gray-600 text-[12px]">2 Stops</p>
              <div className=" relative w-10 md:w-20  h-[1px] bg-primary mt-2">
                <span className=" absolute w-2 md:w-3 h-[1px] inline-block bg-primary -top-1 -right-[2px] rotate-45"></span>
              </div>
            </div>
            <div className=" ">
              <p className="font-medium text-lg text-primary">15:45</p>
              <p className=" text-sm mt-[6px]">YQY</p>
            </div>
            <div className=" md:block hidden">
              <p className="font-medium text-primary">54h 20m</p>
            </div>
          </div>
          {location.search &&
            (roundTrip === "round-way" || roundTrip === "multi-city") && (
              <div className=" w-full flex justify-between items-start flex-wrap gap-1 mt-3">
                <div className="flex items-center gap-1">
                  <img src={bg} alt="" className=" w-12 h-12" />
                  <p className=" text-gray-400 text-[12px]">
                    Emirates Airlines
                  </p>
                </div>
                <div className=" ">
                  <p className="font-medium text-lg text-primary">15:45</p>
                  <p className=" text-sm mt-[6px]">Dubai</p>
                </div>
                <div className=" flex flex-col justify-start items-start sm:items-center">
                  <p className=" text-gray-600 text-[12px]">2 Stops</p>
                  <div className=" relative w-20  h-[1px] bg-primary mt-2">
                    <span className=" absolute w-3 h-[1px] inline-block bg-primary -top-1 -right-[2px] rotate-45"></span>
                  </div>
                </div>
                <div className=" ">
                  <p className="font-medium text-lg text-primary">15:45</p>
                  <p className=" text-sm mt-[6px]">YQY</p>
                </div>
                <div className="">
                  <p className="font-medium text-primary">54h 20m</p>
                </div>
              </div>
            )}
        </div>
        <div className=" col-span-7 md:col-span-2 w-full bg-secoundery rounded-tr h-full px-4 pt-4 pb-4 flex flex-col items-end">
          <p className=" text-primary">INTFLY1110</p>
          <p className=" text-gray-400 line-through text-sm ">AED 451</p>
          <p className=" text-primary font-semibold text-lg">AED 369</p>

          <div
            onClick={gotoDetailsPage}
            className=" w-full px-2 py-1 flex items-center justify-between bg-yellow-400 mt-3 rounded shadow hover:shadow-lg shadow-yellow-500 cursor-pointer"
          >
            <span className=" font-medium">Select</span>
            <HiArrowNarrowRight />
          </div>
        </div>
      </div>
      <span className=" w-full block h-[1px] mb-3 bg-gray-300"></span>
      <div className=" w-full flex items-center justify-between px-5">
        <div
          className=" flex gap-1 items-center cursor-pointer"
          onClick={toggleShowDetails}
        >
          <p className=" text-gray-500 text-[12px] font-medium">
            Partially Refundable
          </p>
          <IoIosArrowDown
            className={` text-gray-600 ${showDetails && "rotate-180"}`}
          />
        </div>
        <p
          onClick={toggleShowDetails}
          className=" text-primary text-[12px] sm:text-sm cursor-pointer"
        >
          {showDetails ? "Hide Flight Details" : "View Flight Details"}
        </p>
      </div>
      <div
        className={` w-full pt-3 ${
          showDetails ? "max-h-screen overflow-y-scroll scrollbar" : "max-h-0"
        } overflow-hidden transition-all ease-in-out duration-500`}
      >
        <div className=" w-full grid grid-cols-2 border-t">
          <div className="w-full md:col-span-1 col-span-2 border-r">
            <div className=" pt-5">
              <p className=" text-center font-bold text-primary pb-1">
                Flight Details
              </p>
              <div className=" flex justify-center">
                <div
                  className=" flex justify-center cursor-pointer"
                  onClick={toggleShowDetailsRoundTrip}
                >
                  <div
                    className={`py-1 px-3 ${
                      showDetailsRoundTrip
                        ? "text-gray-600 border "
                        : "bg-primary text-white "
                    } rounded relative flex items-center justify-center`}
                  >
                    <p className=" z-10">DXB - YQY</p>
                    <div
                      className={`${
                        showDetailsRoundTrip ? " hidden" : "visible"
                      } h-4 w-4 bg-primary absolute -bottom-1 right-1/2 translate-x-1/2 rotate-45 -z-0`}
                    ></div>
                  </div>
                </div>
                {location.search &&
                  (roundTrip === "round-way" || roundTrip === "multi-city") && (
                    <div
                      className=" flex justify-center cursor-pointer"
                      onClick={toggleShowDetailsRoundTrip}
                    >
                      <div
                        className={`py-1 px-3 ${
                          showDetailsRoundTrip
                            ? "bg-primary text-white"
                            : " text-gray-600 border rounded"
                        } rounded relative flex items-center justify-center`}
                      >
                        <p className=" z-10">AED - YQY</p>
                        <div
                          className={`${
                            showDetailsRoundTrip ? " visible" : " hidden"
                          } h-4 w-4 bg-primary absolute -bottom-1 right-1/2 translate-x-1/2 rotate-45 -z-0`}
                        ></div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div>
              <FlightSegment />
              <div className=" w-full flex justify-center py-5">
                <div className=" flex items-center justify-center w-[320px] bg-secoundery rounded p-1 text-sm text-gray-500  px-5">
                  Change plane at{" "}
                  <span className=" text-primary font-medium mx-1">
                    {" "}
                    DOH | 9h 10m
                  </span>{" "}
                  layover
                </div>
              </div>
              <FlightSegment />
              <div className=" w-full flex justify-center py-5">
                <div className=" flex items-center justify-center w-[320px] bg-secoundery rounded p-1 text-sm text-gray-500  px-5">
                  Change plane at{" "}
                  <span className=" text-primary font-medium mx-1">
                    {" "}
                    DOH | 9h 10m
                  </span>{" "}
                  layover
                </div>
              </div>
              <FlightSegment />
            </div>
          </div>
          <div className=" relative md:col-span-1 col-span-2">
            <div className=" flex px-2 border-b">
              <span
                onClick={() => toggleActiveButton("baggage")}
                className={` pt-3 pb-2 px-3 cursor-pointer inline-block text-sm ${
                  activeButton === "baggage"
                    ? "  font-medium text-primary border-b-[2px] border-primary"
                    : " text-gray-600"
                }`}
              >
                Baggage
              </span>
              <span
                onClick={() => toggleActiveButton("flight")}
                className={` pt-3 pb-2 px-3 cursor-pointer inline-block text-sm ${
                  activeButton === "flight"
                    ? "   font-medium text-primary border-b-[2px] border-primary"
                    : " text-gray-600"
                }`}
              >
                Fare
              </span>
              <span
                onClick={() => toggleActiveButton("policy")}
                className={` pt-3 pb-2 px-3 cursor-pointer inline-block text-sm ${
                  activeButton === "policy"
                    ? "   font-medium text-primary border-b-[2px] border-primary"
                    : " text-gray-600"
                }`}
              >
                Policy
              </span>
            </div>

            {activeButton === "baggage" && <Baggage />}

            {activeButton === "flight" && <FlightFear />}
            {activeButton === "policy" && <FlightRules />}
            <div className=" absolute bottom-0 w-full h-10 left-0 bg-secoundery flex justify-between items-center px-5">
              <p>
                {" "}
                <span className=" text-primary font-semibold">Total</span>{" "}
                <span className=" ml-1">(1 Traveler)</span>
              </p>
              <p className=" text-primary font-semibold">AED 369</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlightBookComponent;
