import React from "react";

function Baggage() {
  return (
    <div className=" p-3">
      <table className=" w-full">
        <thead>
          <tr>
            <th className=" text-left text-sm text-gray-400 font-normal">
              Flight
            </th>
            <th className=" text-center text-sm text-gray-400 font-normal">
              Cabin
            </th>
            <th className=" text-right text-sm text-gray-400 font-normal">
              Checkin
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className=" text-sm text-primary py-1">DXB - DOH</td>
            <td className=" text-center text-sm text-primary py-1">7 Kg</td>
            <td className=" text-right text-sm text-primary py-1">2 pieces</td>
          </tr>
          <tr>
            <td className=" text-sm text-primary py-1">DOH - YUL</td>
            <td className=" text-center text-sm text-primary py-1">7 Kg</td>
            <td className=" text-right text-sm text-primary py-1">2 pieces</td>
          </tr>
          <tr>
            <td className=" text-sm text-primary py-1">YUL - YQY</td>
            <td className=" text-center text-sm text-primary py-1">7 Kg</td>
            <td className=" text-right text-sm text-primary py-1">2 pieces</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Baggage;
