import React, { useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import SegmentDetails from "../../Components/FlightBook/SegmentDetails";
import TravelerComponent from "../../Components/TravelerComponent";
import bg from "../../Photos/BG.png";
import TimeoutComponent from "../../Components/FlightBook/TimeoutComponent";
import Baggage from "../../Components/FlightBook/Baggage";
import FlightFear from "../../Components/FlightBook/FlightFear";
import FlightRules from "../../Components/FlightBook/FlightRules";

function FlightBookingDetails() {
  const [dacShowMod, setDACShowMod] = useState(true);
  const [flightShowMod, setFlightShowMod] = useState(true);
  const [faresummery, setFaresummery] = useState(true);
  const [activeButton, setAcctiveButton] = useState("baggage");

  const toggleDACShowMod = () => {
    setDACShowMod(!dacShowMod);
  };
  const togglFaresummery = () => {
    setFaresummery(!faresummery);
  };
  const toggleFlightShowMod = () => {
    setFlightShowMod(!flightShowMod);
  };

  const toggleActiveButton = (text) => {
    setAcctiveButton(text);
  };

  return (
    <div className=" pt-20 w-full">
      <div className="w-full xl:w-[1160px] mx-auto px-5">
        <div className=" w-full flex justify-between flex-wrap mt-2 sm:mt-8">
          <p className=" w-full sm:w-1/2 text-lg sm:text-xl text-primary font-bold">
            Review Your Booking
          </p>
          <p className=" w-full sm:w-auto text-sm sm:text-base">
            {" "}
            <span>Flight Selection</span>
            {` > `} <span className=" text-primary">Booking</span>
          </p>
        </div>
        <div className=" w-full mt-5 grid grid-cols-3 gap-7">
          <div className=" col-span-3 xl:col-span-2">
            <div className=" w-full bg-white shadow-custom rounded">
              <div
                onClick={toggleDACShowMod}
                className=" flex justify-between px-3 sm:px-5 py-3 cursor-pointer"
              >
                <p className=" text-primary font-bold">AED-SYD</p>
                <div className=" flex items-center">
                  <div className=" ml-5 w-7 sm:w-8 h-7 sm:h-8 bg-gray-200 rounded-full flex justify-center items-center transition-all ease-in duration-300 ">
                    <MdOutlineKeyboardArrowDown
                      className={` text-lg sm:text-2xl cursor-pointer ${
                        dacShowMod && "rotate-180"
                      }`}
                    />
                  </div>
                </div>
              </div>
              <SegmentDetails dacShowMod={dacShowMod} />
            </div>

            <div className=" w-full bg-white rounded-md shadow mt-3">
              <div
                onClick={toggleFlightShowMod}
                className=" flex justify-between px-3  cursor-pointer"
              >
                <p className=" p-5 text-primary font-bold ">Flight Details</p>
                <div className=" flex items-center">
                  <div className=" ml-5 w-7 sm:w-8 h-7 sm:h-8 bg-gray-200 rounded-full flex justify-center items-center transition-all ease-in duration-300 ">
                    <MdOutlineKeyboardArrowDown
                      className={` text-lg sm:text-2xl cursor-pointer ${
                        flightShowMod && "rotate-180"
                      }`}
                    />
                  </div>
                </div>
              </div>
              <div
                className={` w-full overflow-y-hidden transition-all ease-in-out duration-500 ${
                  flightShowMod ? " max-h-[300px]" : " max-h-0"
                } `}
              >
                <hr className=" pb-1" />
                <div className=" flex px-2 border-b">
                  <span
                    onClick={() => toggleActiveButton("baggage")}
                    className={` pt-3 pb-2 px-3 cursor-pointer inline-block text-sm ${
                      activeButton === "baggage"
                        ? "  font-medium text-primary border-b-[2px] border-primary"
                        : " text-gray-600"
                    }`}
                  >
                    Baggage
                  </span>
                  <span
                    onClick={() => toggleActiveButton("flight")}
                    className={` pt-3 pb-2 px-3 cursor-pointer inline-block text-sm ${
                      activeButton === "flight"
                        ? "   font-medium text-primary border-b-[2px] border-primary"
                        : " text-gray-600"
                    }`}
                  >
                    Fare
                  </span>
                  <span
                    onClick={() => toggleActiveButton("policy")}
                    className={` pt-3 pb-2 px-3 cursor-pointer inline-block text-sm ${
                      activeButton === "policy"
                        ? "   font-medium text-primary border-b-[2px] border-primary"
                        : " text-gray-600"
                    }`}
                  >
                    Policy
                  </span>
                </div>

                {activeButton === "baggage" && <Baggage />}

                {activeButton === "flight" && <FlightFear />}
                {activeButton === "policy" && <FlightRules />}
              </div>
            </div>

            <div className=" mt-5">
              <p className=" text-gray-500">
                <span className=" text-primary font-bold text-lg">Sign In</span>{" "}
                to book faster and unlock all deals
              </p>
              <div className=" w-full py-3 px-2 bg-white shadow mt-1 rounded">
                <p className=" text-primary underline font-bold">
                  Have a coupon?
                </p>
              </div>
            </div>
            <div className=" mt-5">
              <p className=" text-xl text-primary font-bold ">
                Enter Traveler Details
              </p>
              <form className=" w-full">
                <TravelerComponent />
                <div className=" my-5">
                  <button className=" bg-yellow-500 py-2 px-3 w-full rounded-md font-semibold text-sm text-primary hover:shadow-lg shadow-yellow-300">
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className=" col-span-3 xl:col-span-1 ">
            <div className=" mb-5 bg-white shadow rounded ">
              <TimeoutComponent />
            </div>
            <div className=" w-full bg-white shadow-custom rounded overflow-hidden">
              <div className=" p-5 flex items-center">
                <img src={bg} alt="" className=" w-10" />
                <p className=" ml-2">Emirates Airlines</p>
              </div>
              <div className=" relative">
                <span className=" block w-full h-[1px] bg-gray-300 my-3"></span>
                <div
                  onClick={togglFaresummery}
                  className=" absolute -top-5 right-0 ml-5 w-8 h-8 bg-gray-200 rounded-full flex justify-center items-center transition-all ease-in duration-300 "
                >
                  <MdOutlineKeyboardArrowDown
                    className={` text-2xl cursor-pointer ${
                      faresummery && "rotate-180"
                    }`}
                  />
                </div>
              </div>
              <div
                className={` transition-all ease-in duration-300 ${
                  faresummery ? " max-h-[400px]" : " max-h-0"
                }`}
              >
                <div className=" px-5 py-3">
                  <p className=" mb-3 font-medium">Fare Summary</p>
                  <p className=" mb-1">Adult ( 1 traveller)</p>
                  <div className=" flex justify-between">
                    <p className=" text-gray-500 text-sm">Base Fare</p>
                    <div className=" text-right">
                      <p className=" font-medium">
                        {" "}
                        <span className=" text-gray-500 font-normal">
                          AED
                        </span>{" "}
                        2,645
                      </p>
                      <p className=" text-[12px]">( 1 x 2,645 )</p>
                    </div>
                  </div>
                  <div className=" flex justify-between mt-2">
                    <p className=" text-gray-500 text-sm">Taxes + Fees</p>
                    <div className=" text-right">
                      <p className=" font-medium">
                        {" "}
                        <span className=" text-gray-500 font-normal">
                          AED
                        </span>{" "}
                        725
                      </p>
                      <p className=" text-[12px]">( 1 x 725 )</p>
                    </div>
                  </div>
                </div>
                <span className=" block w-full h-[1px] bg-gray-300 my-3"></span>
                <div className=" w-full flex justify-between px-5 pb-3">
                  <span className=" text-gray-500">Sub-total</span>
                  <p>
                    <span className=" text-gray-500">AED</span> 2,3370
                  </p>
                </div>
                <div className=" w-full bg-secoundery py-5 px-5 flex justify-between">
                  <p className=" font-medium">
                    You Pay{" "}
                    <span className=" text-gray-500 font-normal">
                      (for 1 traveler)
                    </span>
                  </p>
                  <p className=" font-medium">AED 3,370</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlightBookingDetails;
