import { AiFillStar, AiOutlineWifi } from "react-icons/ai";
import hotelPhoto from "../../Photos/hotel/hotel.jpg";
import { ImLocation } from "react-icons/im";
import { MdOutlineAccessibleForward } from "react-icons/md";
import { BsFillDoorOpenFill, BsFillTelephoneFill } from "react-icons/bs";
import { Link } from "react-router-dom";

function HotelBookComponent() {
  return (
    <div className=" w-full bg-white shadow-custom rounded-md p-5 mb-5">
      <Link
        to="/hotel/booking-details"
        className=" w-full grid grid-cols-3 gap-3"
      >
        <div className=" col-span-3 md:col-span-1">
          <div>
            <img src={hotelPhoto} alt="" />
          </div>
        </div>
        <div className=" col-span-3 md:col-span-2">
          <div className=" flex justify-between gap-5 flex-wrap">
            <div>
              <div className=" flex items-center gap-1">
                <div className=" border border-primary rounded px-1 flex items-center justify-center">
                  <span className=" text-primary text-sm">HOTEL</span>
                </div>
                <div className=" flex items-center">
                  <AiFillStar className=" text-primary" />
                  <AiFillStar className=" text-primary" />
                  <AiFillStar className=" text-primary" />
                  <AiFillStar className=" text-primary" />
                  <AiFillStar className=" text-primary" />
                </div>
              </div>
              <p className=" text-base md:text-xl text-primary font-bold">
                Grand Brizo Buenos Aires
              </p>
              <div className=" flex my-2">
                <div className=" flex justify-center items-center px-1 rounded bg-primary text-white font-bold text-sm">
                  <span>4.6/5</span>
                </div>
              </div>
              <div className=" flex items-center placeholder-gray-200 gap-1">
                <ImLocation className=" text-blue-400" />
                <p className=" text-[12px]"> Buenos Aires, Buenos Aires</p>
              </div>
              <div className=" flex mt-2">
                <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                  <MdOutlineAccessibleForward className="" />
                  <p className="  text-[12px]">In-room Accessibility</p>
                </div>
              </div>
              <div className=" flex gap-2 mt-2">
                <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                  <MdOutlineAccessibleForward className="" />
                  <p className="  text-[12px]">Wheelchair Friendly</p>
                </div>
                <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                  <BsFillTelephoneFill className=" text-sm" />
                  <p className="  text-[12px]">24-Hours Frond Desk</p>
                </div>
              </div>
              <div className=" flex gap-2 mt-2">
                <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                  <MdOutlineAccessibleForward className="" />
                  <p className="  text-[12px]">Concierge</p>
                </div>
                <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                  <BsFillDoorOpenFill className=" text-sm" />
                  <p className="  text-[12px]">Bellybody Service</p>
                </div>
                <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                  <AiOutlineWifi className=" text-sm" />
                  <p className="  text-[12px]">Free Wi-Fi</p>
                </div>
              </div>
            </div>
            <div className=" md:text-right">
              <div className=" flex gap-2">
                <p className=" text-gray-400 line-through">AED 2,869</p>
                <p className=" text-xl text-primary font-semibold">AED 2,776</p>
              </div>
              <p className=" text-[12px] text-gray-400">for 1 Night, 1 Rooms</p>
              <div className=" flex md:justify-end">
                <div className=" py-1 px-2 rounded-xl bg-blue-50 flex items-center justify-center">
                  <span className=" text-blue-500 text-[12px]">5% off</span>
                </div>
              </div>
              <p className=" text-[12px] text-blue-400">
                Add discount on payment
              </p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default HotelBookComponent;
