import React from "react";
import logo from "../../Photos/footer-white-log.svg";
import { FaFacebookF } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { SiTwitter } from "react-icons/si";
import { FaLinkedinIn } from "react-icons/fa";
import bankphoto from "../../Photos/bank.png";
import { Link } from "react-router-dom";
import { AiFillYoutube } from "react-icons/ai";

function Footer() {
  return (
    <div className=" w-full py-5 sm:py-10 bg-primary flex justify-center">
      <div className=" w-full xl:w-[1160px] px-5">
        <div className=" grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4 gap-2">
          {/* px-5 sm:px-16 md:px-20 lg:px-32 xl:px-40 2xl:px-64 */}

          <div className=" my-1 lg:my-0">
            <div className=" w-full grid grid-cols-2 gap-1">
              <div>
                <p className="font-semibold text-white mb-2">Discover</p>
                <p className=" text-white text-sm my-1 cursor-pointer">Home</p>
                <p className=" text-white text-sm my-1 cursor-pointer">Terms</p>
                <p className=" text-white text-sm my-1 cursor-pointer">
                  Telent and Culture
                </p>
              </div>
              <div>
                <p className=" text-white text-sm my-1 cursor-pointer pt-5">
                  Privacy Policy
                </p>
                <p className=" text-white text-sm my-1 cursor-pointer">
                  Refund Policy
                </p>
                <p className=" text-white text-sm my-1 cursor-pointer">
                  Privacy Policy
                </p>
              </div>
            </div>
          </div>
          <div className=" my-1 lg:my-0">
            <p className="font-semibold text-white mb-2">Payment Methods</p>
            <img src={bankphoto} alt="" className=" w-64" />
          </div>
          <div className=" my-1 lg:my-0">
            <p className="font-semibold text-white mb-2">Need Help ?</p>
            <p className=" text-white text-sm">
              We are Always here for you! Knock us on Messenger anytime or Call
              our Hotline (10AM - 10PM).
            </p>
          </div>
          <div className=" my-1 lg:my-0">
            <p className=" font-semibold text-white mb-2">Contact</p>
            <p className="my-1 text-[14px] text-white">
              Shams Business Center, Sharjah Media City free Zone, Al Messaned,
              Sharjah, UAE.
            </p>
            <p className="my-1 text-[14px] text-white">+971502984399</p>
            <div className=" flex gap-3 mt-3">
              <div className=" bg-white bg-opacity-25 h-8 w-8 rounded-full text-white hover:opacity-75 hover:text-black flex cursor-pointer justify-center items-center">
                <FaFacebookF className="" />
              </div>
              <div className=" bg-white bg-opacity-25 h-8 w-8 rounded-full text-white hover:opacity-75 hover:text-black flex cursor-pointer justify-center items-center">
                <FiInstagram className="" />
              </div>
              <div className=" bg-white bg-opacity-25 h-8 w-8 rounded-full text-white hover:opacity-75 hover:text-black flex cursor-pointer justify-center items-center">
                <AiFillYoutube className="" />
              </div>
            </div>
          </div>
        </div>
        <span className=" block w-full h-[1px] bg-gray-300 my-3 sm:my-10"></span>
        <div className=" flex justify-between">
          <Link to="/">
            <img src={logo} alt="" className=" w-28" />
          </Link>
          <p className=" text-sm text-white">
            Copyright Globetrottertravels Ltd.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
