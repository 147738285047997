import { AiFillSave } from "react-icons/ai";
import { GiBasketballBasket } from "react-icons/gi";
import { WiDaySunnyOvercast } from "react-icons/wi";
import tourPhoto from "../../Photos/tour/tour.png";
import { ImLocation } from "react-icons/im";
import { MdAccessTimeFilled, MdGroups } from "react-icons/md";
import { FaUmbrellaBeach } from "react-icons/fa";
import { Link } from "react-router-dom";

function TourBookComponent() {
  return (
    <Link
      to="/tour/booking-details"
      className=" block w-full bg-white shadow-custom rounded-md p-5 mb-5"
    >
      <div className=" w-full grid grid-cols-3 gap-3">
        <div className=" col-span-3 md:col-span-1">
          <div className=" h-40">
            <img src={tourPhoto} alt="" className=" w-full h-full" />
          </div>
        </div>
        <div className=" col-span-3 md:col-span-2">
          <div className=" flex justify-between flex-wrap gap-5">
            <div>
              <p className=" text-lg md:text-xl text-primary font-bold">
                Grand Brizo Buenos Aires
              </p>

              <div className=" flex items-center placeholder-gray-200 gap-1 my-2">
                <ImLocation className=" text-primary" />
                <p className=" text-[12px] text-gray-500">
                  {" "}
                  Buenos Aires, Buenos Aires
                </p>
              </div>
              <div className=" flex gap-3 mt-7">
                <div className=" flex gap-1 items-center">
                  <MdAccessTimeFilled className=" text-gray-400 text-sm" />
                  <p className=" text-[12px] text-gray-500">9 hours</p>
                </div>
                <div className=" flex gap-1 items-center">
                  <MdGroups className=" text-gray-400" />
                  <p className=" text-[12px] text-gray-500">
                    From 1 to 49 people
                  </p>
                </div>
              </div>
              <div className=" mt-2 flex gap-1 items-center">
                <AiFillSave className=" text-gray-400" />
                <p className=" text-[12px] text-gray-500">
                  Free cencelation upto 24 hours before start tour
                </p>
              </div>
              <div className=" flex flex-wrap gap-2 mt-2">
                <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                  <GiBasketballBasket className="" />
                  <p className="  text-[12px]">Sighteeing</p>
                </div>
                <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                  <WiDaySunnyOvercast className=" text-sm" />
                  <p className="  text-[12px]">Day tour</p>
                </div>
                <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                  <FaUmbrellaBeach className="" />
                  <p className="  text-[12px]">Beach Activities</p>
                </div>
              </div>
            </div>
            <div className=" md:text-right">
              <p className=" text-sm text-primary ">Starting From</p>
              <p className=" text-primary line-through">AED 2,869 </p>
              <p className="  text-primary font-semibold text-sm my-1">
                AED <span className="text-lg ml-1">2,776</span>{" "}
              </p>
              <p className=" text-[12px] text-primary">Per person</p>
              <div className=" flex md:justify-end mt-1">
                <div className=" py-1 px-2 rounded-xl bg-blue-50 flex items-center justify-center">
                  <span className=" text-blue-500 text-[12px]">5% off</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default TourBookComponent;
