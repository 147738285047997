import React from "react";

function FlightRules() {
  return (
    <div className=" p-3">
      <div className=" my-2">
        <button className=" w-full bg-[#b0dfee] py-2 text-primary font-semibold">
          DXB-YQY
        </button>
      </div>
      <div>
        <p className=" text-sm text-primary border-b border-gray-300 py-1">
          Cancellation
        </p>
        <p className=" text-sm text-gray-500 mt-1">
          Cancellation Fee = Airline's Fee + GoZayaan Fee
        </p>
        <p className=" text-sm text-gray-500">
          Refund Amount = Paid Amount - Cancellation Fee
        </p>
      </div>
      <div className=" mt-2">
        <p className=" text-sm text-primary border-b border-gray-300 py-1">
          Re-issue
        </p>
        <p className=" text-sm text-gray-500 mt-1">
          Re-issue Fee = Airline's Fee + Fare Difference + GoZayaan Fee
        </p>
      </div>
      <p className=" text-sm text-primary mt-5">
        *The airline's fee is indicative and per person. Convenience fee is
        non-refundable.
      </p>
    </div>
  );
}

export default FlightRules;
